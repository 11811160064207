const translationEN = {
    GENERIC_ERROR: 'An error has occured',
    "Email": "Email",
    "First name": "Firstname",
    "Last name": "Lastname",
    "Full name": "Name",
    "Roles": "Roles",
    "Entity": "Entity",
    "Creation date": "Creation date",
    "Actions": "Actions",
    "Password": "Password",
    "Identify": "Identify",
    "First eletric terminals and water network": "First power connections and water network",
    "A service developped by ...": "A service developped by ...",
    "Login": "Login",
    "Logout": "Logout",
    "Account": "My account",
    "Current password": "Current password",
    "New password": "New password",
    "Confirmation": "Confirmation",
    "Your first name": "Your firstname",
    "Your last name": "Your lastname",
    "Your email address": "Your email address",
    "Phone number": "Phone number",
    "Settings": "Personnal information",
    "Security": "Security",
    "Save": "Save",
    "Passwords do not match": "Password do not match",
    "Password successfully changed": "Password successfully changed",
    "Settings successfully changed": "Settings successfully changed",
    "Connection successful": "Successful login",
    "Logout successful": "Successful logout",
    "You must provide a password.": "You must provide a password.",
    "Your password is too short. It should have 12 characters or more.": "Your password is too short. It should have 12 characters or more.",
    "Already connected": "Already connected",
    'Invalid Credentials': "Incorrect email or password",
    "Your password must contain at least one uppercase character.": "Your password must contain at least one uppercase character.",
    "Your password must contain at least one lowercase character.": "Your password must contain at least one lowercase character.",
    "Your password must contain at least one special character.": "Your password must contain at least one special character.",
    "Your password must contain at least one number.": "Your password must contain at least one number.",
    "This password has been leaked in a data breach, it must not be used. Please use another password.": "This password has been leaked in a data breach, it must not be used. Please use another password.",
    "Forgot your password ?": "Forgot your password ?",
    "New ? Create an account": "New ? Create an account",
    "reset_password_title": "A password reset email valid for 30 minutes was sent to the account email address.",
    "reset_password_message": "If you do not receive it, check your junk mail/spam folders or ask your local administrator for help. If not, please contact your administrator.",
    "Create a shipowner account": "Create a shipowner account",
    "remember_me": "Remember me",
    "receive_notifications": "Receive notifications of new shipowner requests",
    "company_information": "Information about your company",
    "Close notification": "Close notification",
    skip_to_main: "Skip to main content",
    page_not_found_title: "404",
    page_not_found_text: "This page does not exist",
    company_name: "Your company name",
    company_identifier: "Company ID",
    company_email: "Billing email",
    address: "Address",
    city: "City",
    zipcode: "Zipcode",
    country: "Country",
    create_password: "Account activation",
    successful_activation: "Account has been successfully activated",
    send: "Send",
    my_premissions: "My permissions",
    entity_permission_request: "Apply with my current information",
    entity_permission_accepted: "Your request has been accepted",
    entity_permission_waiting: "Your request is pending",
    entity_permission_denied: "Your request has been rejected",
    authorization_request_sent: "Request for authorisation sent",
    Users: "Users",
    go_back: "Go back",
    cancel: 'Cancel',
    edit_user: "Edit user",
    add_user: "Add a user",
    Edit: "Edit",
    Delete: "Delete",
    deleteUserTitle: "Deleting a user",
    deleteUserBody: "Are you sure you want to delete this user ?",
    deleteUserValidate: "Yes",
    deleteUserClose: "No",
    userDeleted: "User successfully deleted",
    userModified: "User successfully updated",
    userCreated: "User successfully saved",
    offlineMsg: "You are offline.",
    Disconnected: "You are logged out",
    disconnectedMsg: "You are logged out",
    loading: "Loading...",
    Next: "Next",
    Previous: "Previous",
    Register: "Register",
    HTML__accept_cgu: `I acknowledge that I have read and accepted the present <a href="/pdf/en/cgu.pdf" target="_blank" rel="noreferrer">GCU</a>`,
    acknowledged_warnings: "I acknowledge having read the warnings",
    "First step of registration successful": "First step of registration success",
    "Second step of registration successful": "Second step of registration success",
    "Third step of registration successful": "Third step of registration success",
    "Fourth step of registration successful": "Fourth step of registration success",
    "Last step of registration successful": "Last step of registration success. A confirmation email has been sent to you.",
    statistics: "Statistics",
    history: "History",
    my_ships: "My ships",
    // MENU
    home: "Home",
    menu: 'Menu',
    shortcuts: 'Shortcuts',
    close_menu: 'Close menu',
    close_submenu: 'Close sub-menu',
    home_statistics: "Home - Statistics",
    terminals: "Connection points",
    transaction_history: "Transaction history",
    ships: "Ships",
    tickets: "Tickets",
    updates_note: 'Patch Notes',
    procedures: 'Procedures',
    administration: 'Administration',
        users: 'Users',
        boat_owners: 'Shipowners',
        remote_operations: 'Remote operations',
            start_a_distribution: 'Start a distribution',
            stop_a_distribution: 'Stop a distribution',
            reserve_a_connector: 'Reserve a connector',
            cancel_a_reservation: 'Cancel a reservation',
            restart_a_terminal: 'Restart a connection point',
    terminals_state: 'Connection points status',
    add_ticket: "Add a new ticket",
    ticketAdded: "Ticket successfully added",
    permissions: "My permissions",
    my_history: "Transaction history",
    add_ship: "Add a vessel",
    eni: "ENI (8 numbers)",
    ship_name: "Vessel name",
    ship_email: "Vessel email",
    ship_phone: "Vessel phone",
    shipAdded: "Vessel successfully added",
    shipDeleted: "Vessel successfully set as sold/dismantled",
    shipModified: "Vessel successfully updated",
    deleteShipTitle: "Are you sure you consider this vessel as sold/dismantled?",
    deleteShipBody: "Once validated, this action is irreversible! However, you will still be able to access the transactions made by this boat before the sale/dismantling",
    deleteShipValidate: "I certify that this vessel is sold/dismantled",
    deleteShipClose: "Cancel",
    Show: "Show",
    reply: "Reply",
    code: "Code",
    RegenerateCode: "Generate access code",
    shipCodeRegenerated: "Vessel code has been successfully regenerated and sent by email",
    RegenerateShipCodeTitle: "Are you sure you want to regenerate an access code?",
    RegenerateShipCodeBody: "If you validate, an email will be sent to the vessel's email to communicate the new access code",
    RegenerateShipCodeValidate: "Regenerate the access code",
    RegenerateShipCodeClose: "Cancel",
    empty_input: 'Empty field',
    invalid_input: 'Invalid field',
    ROLE_USER: 'User',
    ROLE_COMPTABLE: 'Accountant',
    ROLE_SUPER_COMPTABLE: 'Super accountant',
    ROLE_TECHNICIEN: 'Technician',
    ROLE_ADMIN: 'Admin',
    ROLE_SUPER_ADMIN: 'Super admin',
    ROLE_ARMATEUR: 'Vessel owner',
    ROLE_NAVIGANT: 'Navigator',
    electric_terminal: "Power connection",
    electric_connectors: "Power connectors",
    water_terminal: "Water connection",
    water_connectors: "Water connectors",
    users_list: 'Users list',
    boat_owners_list: 'Shipowners list',
    terminals_list: 'Connection points list',
    filter_user_placeholder: "Enter a name, an entity...",
    filter_terminal_placeholder: "Enter a name, an address...",
    available: 'Available',
    unavailable: 'Unavailable',
    charging: 'Charging',
    Charging: 'Charging',
    charging_points: 'Charging points',
    show_on_map: 'Show on map',
    go_to_previous_page: 'Go to previous page',
    go_to_next_page: 'Go to next page',
    go_to_page: 'Go to page',
    go_to_first_page: 'Go to first page',
    go_to_last_page: 'Go to last page',
    transaction_id: 'Transaction number',
    place: 'Location',
    terminal: 'Connection point',
    connector: 'Connector',
    connector_count: 'Connector number',
    connector_type: 'Connector type',
    consumption: 'Consumption',
    electric_consumption: 'Electric consumption',
    water_consumption: 'Water consumption',
    duration: 'Duration',
    connection: 'Connection',
    disconnection: 'Disconnection',
    boat_owner: 'Shipowner',
    boat: 'Vessel',
    amount_with_tax: 'Amount including tax',
    pretax_amount: 'Amount HT',
    in_progress: 'In progress ...',
    download: 'Download',
    export_to_xls: 'Export in .xls format (excel)',
    connection_start_date: 'Connection start date',
    connection_end_date: 'Connection end date',
    more_filters: 'More filters',
    less_filters: 'Less filters',
    per_page: 'Items per page',
    identity: 'Identity',
    company: 'Company',
    information: 'Information',
    connections: 'Connections',
    good_to_know: 'Good to know',
    your_company: 'Your company',
    general_informations: 'General information',
    required_fields: 'Required fields',
    siret: "SIRET",
    invalid_siret: "SIRET must be 14 must be 14 digits long",
    intra_community_vat: "Intracommunity VAT",
    fill_vat_or_siret: 'Please fill in at least one of the following two fields: SIRET or Intracommunity VAT.',
    check_all: 'Check all',
    uncheck_all: 'Uncheck all',
    community_certificate: 'Vessel certificate',
    vat_exemption_file: 'Documentary evidence of exemption from VAT',
    numbers_of_transactions: 'Number of transactions',
    ships_list: 'List of vessels',
    edit_ship: 'Edit vessel',
    status: 'Status',
    author: 'Author',
    last_activity: 'Last activity',
    create: 'Create',
    subject: 'Subject',
    open: 'Open',
    HTML__CGU_disclaimer: `
        <h2>Warning and Terms of Service</h2>
        <h3>Warning about charging at the kiosks</h3>
        <p>In order to finalize registration, please read the following warnings carefully:</p>
        <p><strong>To avoid electrical problems with three-phase outlets,</strong> vessels must be equipped with an automatic phase inverter.</p>
        <p><strong>The outlets have a 30 mA differential protection:</strong> For vessels with the neutral connected to the hull, these may have problems with the connection point differential protection tripping.</p>
        <p>You must ensure that you meet one of the following conditions:</p>
        <ul class="unstyled">
            <li>Have a vessel equipped with an isolation transformer</li>
            <li>Verify that the vessel equipped with an insulation monitor does not indicate a fault.</li>
        </ul>
    `,
    brower_compatibily_disclaimer: 'We inform you that some features of the site are not optimized for Internet Explorer, we invite you to use Chrome, Firefox, Edge, or other browsers',
    accepted: 'Accepted',
    refused: 'Refused',
    pending: 'Pending',
    file_input_instruction: 'Drag and drop file or choose one by clicking this frame',
    see_file: 'See file',
    see_kbis_file: 'See KBIS file',
    see_rib_file: 'See RIB file',
    community_certificate_instruction: 'Certificate - PDF (max : 5Mo)',
    see_certificate_file: 'See the certificate file',
    vat_exemption_file_instruction: "VAT exemption file - PDF (max : 5Mo)",
    see_vat_exemption_file: "See the VTA exemption file",
    close: 'Close',
    'ALL': 'All',
    'ELECTRICITE': 'Electricity',
    'EAU': 'Water',
    ignore_null_transactions: 'Ignore 0 kwH/m3 transactions',
    rib_instruction: 'RIB - PDF (Max: 5 Mo)',
    kbis_instruction: 'KBIS - PDF (Max: 5 Mo)',
    update_title: "Update",
    do_update: "Update",
    update_description: "There is a new version available.",
    password_12_characters: 'Password must contain at least 12 characters',
    password_uppercase: 'Password must contain at least one uppercase letter',
    password_lowercase: 'Password must contain at least one lowercase letter',
    password_number: 'Password must contain at least one number',
    password_special_char: 'Password must contain at least one special character',
    password_dont_match: "Confirmation password does not match",
    password_global_error: 'Your password does not comply with the security standards in force (12 characters minimum, including a capital letter, a number, and a special character)',
    ticket_reason: 'Reason for ticket',
    ticket_message: 'Message',
    terminal_details: 'Connection point Details',
    open_terminal_manual: 'Terminal usage',
    import_terminal_manual: 'Upload manual',
    replace_terminal_manual: 'Replace manual',
    unavailable_charge: 'Charge indisponible',
    name: 'Name',
    entity: 'Entity',
    version: 'Version',
    actions: 'Actions',
    type: 'Type',
    user_refusal_reason: "Reason for shipowner's rejection",
    accept: 'Accept',
    decline: 'Decline',
    confirm: 'Confirm',
    'Expired or invalid token': 'Invalid token. Please reapply.',
    cgu: 'GCU',
    borne_et_eau_explanatory: 'Borne et Eau explanatory',
    user_guide: 'User guide',
    remote_startup_note: 'Remote startup note',
    back_to_login: 'Go back to the login form',
    active:'Active',
    deleted:'Deleted',
    show_deleted_boats: 'Show deleted vessels',
    new: 'New',
    update : 'Update',
    password_different: 'New password must be different from the old one',
    "Your password must be different from your previous.": 'New password must be different from the old one',
    allowed: 'Allowed',
    denied: 'Denied',
    waiting: 'Pending',
    reason: 'Reason',
    tickets_list: 'Tickets list',
    ticket_details: 'Ticket details',
    min_1_max_500: '1 character minimum, 500 maximum',
    display_all: 'Display all',
    ticket_terminal: 'Select a connection point if necessary',
    issue_terminal_panel : 'Issue with the panel of the connection point',
    issue_interface_operation: 'Issue with the interface operation',
    issue_payement: 'Issue with the payement',
    issue_damaged_terminal: 'Connection point damaged',
    issue_personnal_data: "Deletion of personal data",
    issue_other: 'Other issue',
    show_closed_tickets: 'Show resolved tickets',
    DATE_FORMAT: "MM/DD/yyyy",
    DATE_HOURS_FORMAT: "MM/DD/yyyy HH:mm:ss",
    ticket_open: 'Open',
    ticket_closed: 'Resolved',
    ticket_waiting_answer: 'In process of resolution',
    blocked_file_popup: "Could not open file. Check if your browsers blocked pop-ups",
    ticket_comment_sent: 'Comment sent',
    ticket_status_updated: 'Ticket status updated',
    identification: 'Identification',
    Occupied: "Occupied",
    Available: "Available",
    Unavailable: "Unavailable",
    AvailableFull: 'Available',
    OccupiedFull: 'Fully occupied',
    UnavailableFull: 'Fully unavailable',
    UnavailableOccupied: 'Partly unavailable',
    UnavailableOccupiedFull: 'Unavailable',
    Preparing: "Preparing",
    Offline: 'Hors Ligne',
    'Unknown': 'Unknown',
    date: 'Date',
    graphical_version: 'Graphical version',
    accessible_version: 'Accessible version',
    show_fullscreen: 'Display in fullscreen',
    distribution_finished: 'No. of distributions',
    distribution_running: 'No. of ongoing distributions',
    distribution_zero: 'No. of distributions at zero',
    "Unauthorized file type. Expecting 'application/pdf', got image/jpeg": "Only PDF files are accepted.",
    "You must accept the CGU and acknowledge the warnings": "You must accept the GCU and acknowledge the warnings",
    min_date_message_transactions: 'Start date cannot be greater than end date',
    electric_connectors_state: "Status of available and occupied connection points",
    electric_distribution: "Electricity distribution",
    electric_distribution_state: "Status of electricity distribution",
    electric_consumption_state: "Status of electricity consumption",
    water_connectors_state: "Status of available and occupied connection points",
    water_distribution: "Water distribution",
    water_distribution_state: "Status of water distribution",
    water_consumption_state: "Water consumption status",
    tour_title: `Would you like to take a tour ?`,
    tour_description: `New user? Discover the features of the Borne&Eau website by following this short tour.`,
    tour_skip: `I already know the website`,
    tour_start: `Let's get started`,
    tour_content_statistics: `Here you will find the statistics showing the consumption of your vessels as well as the number of distributions.`,
    tour_content_terminals: `Here you will find all the connection points as well as their status (Online, Charging, Offline).`,
    tour_content_transaction_history: `Find your recent or old consumptions thanks to a filtering system. You can also download them in Excel format in 1 click with the filtering of your choice.`,
    tour_content_ships: `All your vessels are registered here. No vessels registered yet? Create one to receive the code to recharge your vessel on the connection points. You can also delete your vessel (sale/dismantling), you will keep the transactions associated to this vessel.`,
    tour_content_tickets: `If you encounter a problem on the site or on a connection point, do not hesitate to open a ticket, we will answer you as soon as possible.`,
    tour_content_updates_note: `We update the website as often as possible and you can see the updates and fixes on this page.`,
    tour_content_procedures: `Find here the General Terms of Use (GTU) and an explanatory PDF of the Borne&Eau project.`,
    tour_content_account: `Access your account by clicking here. You can change your password, personal data, company data and manage port permissions.`,
    tour_content_pwa: `<p>To find the application from the home screen of your tablet or phone :</p><ol><li>Open the application https://www.borneeteau.fr/login in your browser</li><li>Click on Share</li><li>Select "On the home screen".</li></ol>`,
    "Missing required file 'kbis_file'": "KBIS file must be provided",
    "Missing required file 'rib_file'": "RIB file must be provided",
    pdf_wrong_format: 'File must be in pdf format',
    pdf_size_error: 'File must be 5MB at most',
    'You are not allowed to change the status for this entity': 'You are not allowed to change the status of this entitiy',
    last_update: 'Last update',
    report_unavailable: 'Report as unavailable',
    'Unable to start a busy connector': 'Unable to start a busy connector',
    'Unable to disable a busy connector': 'Unable to disable a busy connector',
    replay_tutorial: 'Replay Tutorial',
    'A user with this email address already exist': 'A user with this email address already exist',
    reactivate_user: 'Reactivate user',
    reactivate_user_description: 'User already exists but has been desactivated. Do you want to reactivate him ?',
    user_restored: "User has been successfully restored",
    token_expired_please_reapply: 'Link has expired. Please reapply',
    show_boat_transactions: "See the vessel's consumption history",
    quantity: 'Quantity',
    "Vessel not found": "Vessel not found",
    recap_available: 'Available',
    recap_occupied: 'Occupied',
    recap_error: 'Error',
    recap_online: 'Online',
    recap_offline: 'Offline',
    recap_unknown: 'Unknown',
    "You are not allowed to access to this vessel": "You are not allowed to access this vessel",
    transaction_start_success : 'Transaction successfully started',
    transaction_stop_success : 'Transaction successfully stopped',
    transaction_count: 'Transactions count',
    total: 'Total',
    this_week: 'This week',
    today: 'Today',
    price_group: 'Price group',
    per_year: 'Per year',
    per_month: 'Per month',
    per_week: 'Per week',
    per_day: 'Per day',
    proceed_with_disconnection: "You can now proceed with the disconnection operations on the terminal",
    about_personnal_data: "About personnal data",
    HTML__personnal_data: `
        <h2>Personal data</h2>
        <p>In accordance with the <strong>European Data Protection Regulation and national regulations (the amended Data Protection Act of 6 January 1978), you are informed that the personal data recorded are those provided by the online registration form.</strong></p>
        <p>The site may also collect connection data for <strong>information, statistical and audience analysis</strong> purposes under the conditions indicated in § Connection cookie.</p>
        <p><strong>These data are solely intended for VNF,</strong> as the data controller, for administrative management purposes.</p>
        <p>It is your responsibility to ensure that the information you provide to us through this site is accurate and complete.</p>
        <p>You may exercise your right of access, opposition and rectification by contacting :</p>
        <h3>By mail to :</h3>
        <address>
            Voie navigables de France <br/>
            175 rue Ludovic Boutleux <br/>
            62408 Béthune
        </address>
        <h3>By phone :</h3>
        <p>Tel.: 33 (0)3 21 63 24 24</p>
        <h3>Par email to :</h3>
        <p><a href="mailto:dpd@vnf.fr">dpd@vnf.fr</a></p>
    `,
    'Unable to reserve a busy connector': "Unable to reserve a busy connector",
    'Unable to reserve a disabled connector': "Unable to reserve a disabled connector",
    'You are not allowed to access this terminal': "You are not allowed to access this connection point",
    'Reservation has already ended': "Reservation has already ended",
    'Unable to retrieve Reservation from CPO': "Unable to retrieve Reservation from CPO",
    'User not found': "User not found",
    'Account exists': "Account exists",
    "ENI already in use": "ENI already in use",
    'Invalid file type.': "Invalid file type.",
    'File not found': "File not found",
    'Entity not found': "Entity not found",
    'Permission already exists': "Permission already exists",
    'Step accessed too early': "Step accessed too early",
    'Parameter \'entities\' must be an array of ids': "Parameter 'entities' must be an array of ids",
    'no reset token provided': "no reset token provided",
    'Unable to start a disabled connector': "Unable to start a disabled connector",
    'Unable to stop a non-charging connector': "Unable to stop a non-charging connector",
    'No transaction found at this connector for this vessel': "No transaction found at this connector for this vessel",
    'You are not allowed to edit this user': "You are not allowed to edit this user",
    'You are not allowed to edit this boat': "You are not allowed to edit this boat",
    'Unable to delete yourself': "Unable to delete yourself",
    'You are not allowed to delete this user': "You are not allowed to delete this user",
    'Unable to restore yourself': "Unable to restore yourself",
    'Unable to recover a non-deleted user': "Unable to recover a non-deleted user",
    'You are not allowed to restore this user': "You are not allowed to restore this user",
    'Forbidden': "Forbidden",
    'Charging started successfully': "Charging started successfully",
    'Charging stopped successfully': "Charging stopped successfully",
    'Connector reserved successfully': "Connector reserved successfully",
    'Connector reservation canceled successfully': "Connector reservation canceled successfully",
    'Water': 'Water',
    'Electricity': 'Electricity',
    'Arrêter ou démarrer la distribution': "Stop or start distribution",
    'Démarrer': "Start",
    'Arrêter': "Stop",
    'report_available_success' : 'Connector has been reported as available',
    'report_unavailable_success' : 'Connector has been reported as unavailable',
    see_boat: 'See vessel',
    terminal_reset_success: 'Connection point has been successfully reset',
    terminal_manual_update_success: 'Terminal manual has been successfully updated',
    password_helper_intro: 'Your password must contain at least :',
    password_helper_length: '12 characters',
    password_helper_uppercase: 'One uppercase',
    password_helper_digit: 'One number',
    password_helper_specialchar: 'One special character',
    contact_vnf: "VNF contact",
    contact_haropa: "HAROPA PORT contact",
    fixes: 'Bugfix(es)',
    features: 'Feature(s)',
    inactivity_title: `Prolonged inactivity period`,
    inactivity_desc: `The displayed data is at risk of not being up to date. Please reload the page to refresh the data.`,
    refresh_page: 'Reload page',
    bassin: 'Bassin',
};
export default translationEN;