import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import Pagination from '../../components/Pagination';
import IconButton from 'components/IconButton';
import CreateTicket from './_createModal';
import EditTicket from './_editModal';
import { CheckboxFilter, SelectFilter } from 'components/Filters';
import ScrollContainer from 'react-indiana-drag-scroll';

class Ticket extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            ticket: {
                list: []
            },
            roles: [],
            isActive: true,
            currentPage: 1,
            pageTotal: 1,
            filter: {
                show_closed_tickets: false,
                bassin: 'all',
                per_page: 15
            },
            bassins: [],
            editTicket: null, // null or ticket
            createTicket: false, // true or false
            sortField: null,
            sortDirection: 'ASC',
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(['ticket'], [this.state.ticket]);
            this.setState({ roles: this.loadRoles() });
            this._isMounted && this.getTickets();
            this._isMounted && this.getBassins();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value
        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: ''
            },
            currentPage: value,
        }), this.getTickets)
    }

    onFilterInputChange = (e) => {
        const term = e.target.value

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: term
            },
        }), this.getTickets)
    }

    async getBassins() {
        let bassinsFilters = [{ value: 'all', name: 'display_all'}];

        // while remaining pages
        let page = 1;
        let hasRemaingPages = false;
        do {
            try {
                const url = `/bassin/list/all?limit=50&page=${page}`;
                const data = await this.request(url, 'GET');
                if (!data) break;

                const { bassins, nbPages } = data;

                bassinsFilters = [
                    ...bassinsFilters,
                    ...bassins.map(bassins => ({value: bassins.cpoCode, name: bassins.name}))
                ];

                hasRemaingPages = nbPages > page;
                if (hasRemaingPages) page++;
            } catch (e) {
                hasRemaingPages = false;
                console.error(e);
            }
        } while (hasRemaingPages);
        
        this.setState({ bassins: bassinsFilters });
    }

    async getTickets() {
        const page = this.state.currentPage;
        const limit = this.state.filter.per_page;
        const {show_closed_tickets, bassin} = this.state.filter;
        
        let url = `/ticket/?limit=${limit}&page=${page}&resolved=${show_closed_tickets ? 'true' : 'false'}`;

        if (bassin !== 'all') {
            url += `&bassin=${bassin}`;
            this.setState({isActive: true});
            console.log({bassin})
        }

        let method = "GET";
        // pagination
        this.setState({
            currentPage: page
        })

        let data = await this.request(url, method);
        if (data && data.tickets) {
            this._isMounted && this.setState(prevState => ({
                ticket: {
                    ...prevState.ticket,
                    list: data.tickets
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
            }), () => {
                localStorage.setItem('ticket', JSON.stringify(this.state.ticket));
            });
        }
        else {
            this.setState({ isActive: false });
        }
    }

    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getTickets())
    }

    render() {
        const { t } = this.props;
        return (
            <DefaultLayout>
                <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')} />
                <h1 className="main-title">{t('tickets')}</h1>
                <div className="table-header__container table-header__container--with-action">
                    <div className="table-header table-header--blue">
                        <h2 className="table-header__title">{t('tickets_list')}</h2>
                        <div className="row">
                            <div className="col-md-6 col-lg-8 mb-4 mb-md-0">
                                <CheckboxFilter
                                    id='show_closed_tickets'
                                    name='show_closed_tickets'
                                    label={t('show_closed_tickets')}
                                    value={this.state.filter.show_closed_tickets}
                                    handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getTickets())}/>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <SelectFilter
                                    id="bassin"
                                    name="bassin"
                                    label={t('bassin')}
                                    value={this.state.filter.bassin}
                                    handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getTickets())}
                                    showLabel
                                    small
                                    options={this.state.bassins}/>
                            </div>
                        </div>
                    </div>
                    <div className="table-header__actions">
                        <button onClick={() => this.setState({createTicket: true})} className="table-header__link"><i className="vnf-icons-icon-m-plus-rond-solid"></i>{t('add_ticket')}</button>
                        {/* <Link to="/ticket/new" className="table-header__link"><i className="vnf-icons-icon-m-plus-rond-solid"></i>{t('add_ticket')}</Link> */}
                    </div>
                </div>
                <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                    <table className="card-table card-table--blue">
                        <thead>
                            <tr>
                                <th>{t('id')}</th>
                                <th>{t('subject')}</th>
                                <th>{t('terminal')}</th>
                                <th>{t('create')}</th>
                                <th>{t('last_activity')}</th>
                                <th>{t('author')}</th>
                                <th>{t('status')}</th>
                                <th>{t('Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.ticket.list.map((ticket) =>
                                    <tr key={ticket.id}>
                                        <td data-label={t('id')}>{ticket.id}</td>
                                        <td data-label={t('subject')}>{t(ticket.reason)}</td>
                                        <td data-label={t('terminal')}>{ticket.terminal ? ticket.terminal.cpoTerminalId : '-'}</td>
                                        <td data-label={t('create')}>{moment.utc(ticket.created_at.date).format(t('DATE_HOURS_FORMAT'))}</td>
                                        <td data-label={t('last_activity')}>{moment.utc(ticket.lastUpdated.date).format(t('DATE_HOURS_FORMAT'))}</td>
                                        <td data-label={t('author')}>{ticket.author.firstName + " " + ticket.author.lastName}</td>
                                        <td data-label={t('status')}>{t('ticket_' + ticket.status)}</td>
                                        <td className="card-table__actions card-table__min-width">
                                            <IconButton
                                                onClick={() => this.setState({editTicket: ticket})}
                                                label={t('Show')}
                                                icon="vnf-icons-icon-m-oeil-outline"
                                                focusIcon="vnf-icons-icon-m-oeil-solid"
                                                color="bleu-a3"/>
                                            {/* <Link to={"/ticket/" + ticket.id}>{t('Show')}</Link> */}
                                        </td>
                                        {/* {this.state.roles.includes("ROLE_SUPER_ADMIN") && <td><Link className="card-table__action" to={"/ticket/edit/" + value.id}>{t('Edit')}</Link></td>}
                                        {this.state.roles.includes("ROLE_SUPER_ADMIN") && <td><Link className="card-table__action" to={"/ticket/delete/" + value.id}>{t('Delete')}</Link></td>} */}
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </ScrollContainer>

                {(this.state.pageTotal > 1 && this.state.ticket.list.length > 0) && (
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />
                )}

                {this.state.createTicket && <CreateTicket onClose={() => this.setState({createTicket: false}, this.getTickets)} />}
                {this.state.editTicket && <EditTicket ticket={this.state.editTicket} onClose={() =>this.setState({editTicket: null}, this.getTickets)} />}
            </DefaultLayout>
        )
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Ticket)), Ticket));
