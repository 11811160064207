export const REGEX = {
    email: /^[\w-.]+@([\w-]+\.)+[\w-]+$/g,
    name: /^([^\x21-\x26\x28-\x2C\x2E-\x40\x5B-\x60\x7B-\xAC\xAE-\xBF\xF7\xFE]+)$/g,
    password_12_characters: /^.{12,}$/,
    password_uppercase: /([A-Z])/,
    password_lowercase: /([a-z])/,
    number: /([0-9])/,
    password_special_char: /([^A-Za-z0-9 ])/,
    ENI: /^[0-9]{8}$/,
    SIRET: /^[0-9]{14}$/,
    VAT: /^[a-zA-Z]{2}[a-zA-Z\d.\s]+[a-zA-Z\d]+$/,
}

export const validatePdf = (e) => {
    const file = e.target.files[0]
    const {type, size} = file
    if (type !== 'application/pdf') return {type: 'erreur', message: 'pdf_wrong_format'}
    if (size > 5 * 1024 * 1024) return {type: 'erreur', message: 'pdf_size_error'}

    return null
}

export const validateEmail = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.email).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_input'}
}

export const validateName = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.name).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_input'}
}

export const validatePasswordMatch = (value, targetValue) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return value === targetValue
        ? null
        : {type: 'erreur', message: 'password_dont_match'}
}

export const validatePassword = (value, currentPassword = null) => {
    if (currentPassword && value === currentPassword) {
        return {type: 'erreur', message: 'password_different'}
    }

    const validations = [
        {regex: REGEX.password_12_characters, message: 'password_12_characters'},
        {regex: REGEX.password_uppercase, message: 'password_uppercase'},
        {regex: REGEX.password_lowercase, message: 'password_lowercase'},
        {regex: REGEX.number, message: 'password_number'},
        {regex: REGEX.password_special_char, message: 'password_special_char'},
    ]

    for (let validation of validations) {
        // const {regex, message} = validation
        // if (!new RegExp(regex).test(value)) return { type: 'erreur', message}
        const {regex} = validation
        if (!new RegExp(regex).test(value)) return { type: 'erreur', message: 'password_global_error'}
    }

    return null
}

export const validateNumber = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.number).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_input'}
}

export const validateSIRET = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.SIRET).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_siret'}
}

export const validateVAT = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.VAT).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_input'}
}


export const validateENI = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'}

    return new RegExp(REGEX.ENI).test(value)
        ? null
        : { type: 'erreur', message: 'invalid_input'}
}

export const validateTicketMessage = (value) => {
    return value.length >0 && value.length <= 500
        ? null
        : { type: 'erreur', message: 'min_1_max_500'}
}
export const validateAddress = (value) => {
    if (value === '') return { type: 'erreur', message: 'empty_input'};
    return null;
}