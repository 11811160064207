import { SvgIcon } from "@material-ui/core";
import React from "react";

/*
                             @@@@
                             @@@@@@@
                           @@@@@@@@
                         @@@@@@@@@
                       @@@@@@@@@
                      @@@@@@@@@
                    @@@@@@@@@
                 @@@@@@@@@@@
               @@@@@@@@@@@@
             @@@@@@@@@@@@@@@@@@@@@@@@@
           @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
          @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
          @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
               @@@@@@@@@@@@@@@@@@@@@@@@@@@
                           @@@@@@@@@@@@
                          @@@@@@@@@@@
                         @@@@@@@@@@
                       @@@@@@@@@@
                      @@@@@@@@@
                     @@@@@@@@
                   @@@@@@@@
                  @@@@@@@
                   @@@@
*/

/**
 * Represents an electric bolt icon.
 *
 * @component
 * @param {object} props - The props for the ElectricBolt component.
 * @returns {JSX.Element} The rendered ElectricBolt component.
 */
const ElectricBolt = (props) => <SvgIcon {...props}>
    <path
        d="M14.69 2.21 4.33 11.49c-.64.58-.28 1.65.58 1.73L13 14l-4.85 6.76c-.22.31-.19.74.08 1.01.3.3.77.31 1.08.02l10.36-9.28c.64-.58.28-1.65-.58-1.73L11 10l4.85-6.76c.22-.31.19-.74-.08-1.01-.3-.3-.77-.31-1.08-.02">
    </path>
</SvgIcon>


export default ElectricBolt;