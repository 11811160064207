import { SvgIcon } from "@material-ui/core";
import React from "react";

/*
                       @@@@
                     @@@@@@@@
                    @@@@@@@@@@@
                  @@@@@@@@@@@@@@
                @@@@@@@@@@@@@@@@@@
              @@@@@@@@@@@@@@@@@@@@@@
             @@@@@@@@@@@@@@@@@@@@@@@@
            @@@@@@@@@@@@@@@@@@@@@@@@@@
          @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
          @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
         @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@ @@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@  @@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@  @@@@@@@@@@@@@@@@@@@@@@@
          @@@@@@@@    @@@@@@@@@@@@@@@@@@
          @@@@@@@@@@@@   @@@@@@@@@@@@@@@
            @@@@@@@@@@@@@@@@@@@@@@@@@@@
             @@@@@@@@@@@@@@@@@@@@@@@@
               @@@@@@@@@@@@@@@@@@@@
                   @@@@@@@@@@@@
*/

/**
 * Represents a water drop icon.
 *
 * @param {object} props - The component props.
 * @returns {JSX.Element} - The rendered WaterDrop component.
 */
const WaterDrop = (props) => <SvgIcon {...props}>
    <path d="M12 2c-5.33 4.55-8 8.48-8 11.8 0 4.98 3.8 8.2 8 8.2s8-3.22 8-8.2c0-3.32-2.67-7.25-8-11.8M7.83 14c.37 0 .67.26.74.62.41 2.22 2.28 2.98 3.64 2.87.43-.02.79.32.79.75 0 .4-.32.73-.72.75-2.13.13-4.62-1.09-5.19-4.12-.08-.45.28-.87.74-.87"></path>
</SvgIcon>

export default WaterDrop;